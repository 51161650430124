$.extend( $.fn.dataTable.defaults, {
    "language": {
        "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
        "sInfo":            "_START_ bis _END_ von _TOTAL_ Einträgen",
        "sInfoEmpty":       "0 bis 0 von 0 Einträgen",
        "sInfoFiltered":    "(gefiltert von _MAX_ Einträgen)",
        "sInfoPostFix":     "",
        "sInfoThousands":   ".",
        "sLengthMenu":      "_MENU_ Einträge anzeigen",
        "sLoadingRecords":  "Wird geladen...",
        "sProcessing":      "<div class='inner'><i class='fa fa-refresh fa-spin fa-3x fa-fw'></i><span>Daten werden geladen...</span></div>",
        "sSearch":          "Suchen",
        "sZeroRecords":     "Keine Einträge vorhanden.",
        "oPaginate": {
            "sFirst":       "Erste",
            "sPrevious":    "Zurück",
            "sNext":        "Nächste",
            "sLast":        "Letzte"
        },
        "oAria": {
            "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
            "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
        },
        "select": {
            "rows": {
                "_": "&nbsp;, %d Einträge sind makiert.",
                "0": "",
                "1": "&nbsp;, 1 Eintrag ist makiert"
            }
        }
    },
    "aLengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "Alle"]],
    "iDisplayLength": 25,
    "aaSorting": [],
    "select": {
        style: 'multi'
    },
    "stateSave": true,
} );

jQuery(document).ready(function($) {
    $(document).on("click", ".dataTable .delete, .ajax-delete",function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var url = $(this).parents('form').attr('action');
        var id = $(this).parents("tr").attr('id');
        var form = $(this).parents('form');
        var table = ($(this).parents("table").length > 0 ? $(this).parents("table") : $(this).parents(".card").find("table"));

        var title = ($.trim($(this).data('title')) != '' ? $(this).data('title') : 'Eintrag löschen');
        var description = ($.trim($(this).data('description')) != '' ? $(this).data('description') : 'Wollen Sie diesen Eintrag wirklich löschen?');
        swal.fire({
            title: title,
            icon: 'warning',
            html: description,
            showCancelButton: true,
            confirmButtonText: 'Löschen!',
            cancelButtonText: 'Abbrechen'
        }).then((result) => {
            if (result != null && result.value) {
                $.ajax({
                    type: "POST",
                    url: url,
                    data: form.serialize(),
                    dataType: "json",
                    success: function(json){
                        alertify.success(json.message);
                        table.DataTable().draw(true);
                    },
                    failure: function(errMsg) {
                    }
                });
            }
        });
    });
});
