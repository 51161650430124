jQuery(document).ready(function($) {
    console.debug(1);
    $(document).on("click", ".lead-history-entry .btn-delete",function(e) {
        e.preventDefault();

        var self = $(this);
        var title = 'Eintrag löschen';
        var description = 'Wollen Sie diesen Eintrag wirklich löschen?';
        var url = $(this).attr('href')
        swal.fire({
            title: title,
            icon: 'warning',
            html: description,
            showCancelButton: true,
            confirmButtonText: 'Löschen!',
            cancelButtonText: 'Abbrechen'
        }).then((result) => {
            if (result != null && result.value) {
                self.parents(".lead-history-entry").remove();
                $.ajax({
                    type: "GET",
                    url: url,
                    dataType: "json",
                    success: function(json){
                        alertify.success(json.message);
                    },
                    failure: function(errMsg) {
                    }
                });
            }
        });
    });

    tinymce.init({
        selector: 'textarea#inp-text',
        plugins: "emoticons",
        toolbar: "emoticons",
        content_style: "p {margin: 0}",
        menubar: false,
        setup: function(ed) {
            ed.on('change', function(e) {
                if (jQuery(".content__paragraph").length > 0) {
                    jQuery(".content__paragraph").html(ed.getContent());
                }
            });
        }
    });
});


