jQuery(document).ready(function($) {

    $(".codemirror").each(function() {
       var id = $(this).attr('id');

        var editor = CodeMirror.fromTextArea(document.getElementById(id), {
            height: "150px",
            textWrapping: true,
            theme: 'material-darker',
            lineNumbers: true,
            extraKeys: {
                'Tab': 'emmetExpandAbbreviation',
                'Esc': 'emmetResetAbbreviation',
                'Enter': 'emmetInsertLineBreak'
            },
            emmet: {
                mark: true,
                markTagPairs: true,
                previewOpenTag: false,
                autoRenameTags: true,
                preview: false
            }
        });
    });
});
