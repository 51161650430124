jQuery(document).ready(function($) {
    $(document).on("click", ".card .save",function(e) {
        $(this).parents(".card").find("form:not(.upload-form)").submit();
    });

    $(document).on("click", ".card .reload",function(e) {
        e.preventDefault();
        $(this).parents(".card").find("table").DataTable().draw(true);
    });
});

var cardHeaderOffsetTop = 0;
jQuery(document).ready(function(){
    $(".card-header").not(".disablefix").each(function() {
        $(this).attr('offset-top', $(".card-header").offset().top - $("header.header").outerHeight());
    });
});

jQuery(window).scroll(function() {
    $(".card-header").not(".disablefix").each(function() {
        if (!$(this).hasClass("no-fix-header") && $(this).attr('offset-top') > 0) {
            if ($(document).scrollTop() > $(this).attr('offset-top')) {
                $(this)
                    .addClass("shrink")
                    .css('width', $(this).parent().width())
                    .css('top', $("header.header").outerHeight());
                $(this).parent().css('padding-top', $(this).outerHeight());
            } else {
                $(this)
                    .removeClass("shrink")
                    .css('width', 'auto')
                    .css('top', '');
                $(this).parent().css('padding-top', '');
            }
        }
    });
});

var lastScrollTop = 0;
jQuery(window).scroll(function(event) {
    if ($(".card-header .btn-action [data-action='save']").length > 0) {
        var offsetTop = $(".card-header .btn-action [data-action='save']").offset().top;
        var elementHeight = $(".card-header .btn-action [data-action='save']").height();
        elementHeight = 0;

        var st = $(this).scrollTop();
        if (st > lastScrollTop && $(document).scrollTop() > (offsetTop + elementHeight)){
            jQuery(".fixed-save-button-container").slideDown();
        } else {
            if ($(document).scrollTop() > (offsetTop + elementHeight)) {
                jQuery(".fixed-save-button-container").slideUp();
            }
        }

        lastScrollTop = st;
    }
});
