window.facebookPostUploadCompleted = function(data) {

    if(data == "delete") {
        jQuery(".post__content .preview-image").remove();
        jQuery(".post__content .preview-video").remove();

        jQuery(".post__content .preview-image-placeholder, .post__content .preview-video-placeholder").removeClass("d-none");
    } else {
        jQuery(".post__content .preview-image-placeholder, .post__content .preview-video-placeholder").addClass("d-none");

        data.files.files.forEach(function(file){
            console.debug(file);

            if (file.type.indexOf("video") > -1) {
                var video = $('<video />', {
                    src: file.file,
                    type: 'video/mp4',
                    class: "content__image preview-video",
                    controls: true,
                    autoplay: true,
                    loop: true
                });
                video.appendTo($('.post__content'));
            }
            if (file.type.indexOf("image") > -1) {
                var video = $('<img />', {
                    src: file.file,
                    type: 'video/mp4',
                    class: "content__image preview-image",
                });
                video.appendTo($('.post__content'));
            }
        });
    }
}

