jQuery(document).ready(function($) {
    jQuery(".service-container input[name='services[]']").change(function() {
        jQuery(this).parents(".service-container").find(".services-settings").slideToggle();
    });
});
jQuery(document).ready(function($) {
    jQuery(".selectAll").click(function(e) {
        e.preventDefault();

        jQuery(this).parent().find("input[type='checkbox']").attr('checked', 'checked');
    });
});
