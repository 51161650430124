jQuery(document).ready(function($) {

// enable fileuploader plugin
// $('input[name="files"]').fileuploader({
//     addMore: true,
//     lang: 'de',
//     thumbnails: {
//         onItemShow: function(item) {
//             // add sorter button to the item html
//             item.html.find('.fileuploader-action-remove i').attr('class', 'zmdi zmdi-close');
//             item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Sort"><i class="zmdi zmdi-arrows"></i></button>');
//         }
//     },
//     sorter: {
//         selectorExclude: null,
//         placeholder: null,
//         scrollContainer: window,
//         onSort: function(list, listEl, parentEl, newInputEl, inputEl) {
//             // onSort callback
//         }
//     },
//     captions: $.fn.fileuploader.languages.de
// });

$form = $('.webinardownloadfiles-uploadform');

    // enable fileuploader plugin
    $('input[name="files"]', $form).fileuploader({
        changeInput: ' ',
        theme: 'boxafter',
        enableApi: true,
        thumbnails: {
            box: '<div class="fileuploader-input">' +
                '<div class="fileuploader-input-inner">' +
                '<h3>${captions.feedback} ${captions.or} <a>${captions.button}</a></h3>' +
                '</div>' +
                '<button type="button" class="fileuploader-input-button"><i class="zmdi zmdi-plus"></i></button>' +
                '</div>' +
                '<div class="fileuploader-items">' +
                '<ul class="fileuploader-items-list"></ul>' +
                '</div>',
            item: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-wrapper">' +
                '<div class="columns">' +
                '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
                '<div class="column-title">' +
                '<div title="${name}">${name}</div>' +
                '<span>${size2}</span>' +
                '</div>' +
                '<div class="column-actions">' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>' +
                '${progressBar}' +
                '</div>' +
                '</div>' +
                '<div class="input-title">' +
                '<div class="input-group mt-1">' +
                '    <input type="text" class="form-control" value="" placeholder="Datei-Titel">' +
                '<div class="input-group-append">' +
                '    <button class="btn btn-success btn-save-title" type="button" data-updateurl=""><i class="zmdi zmdi-save"></i></button>' +
                '  </div>' +
                '</div>' +
                '</div>' +
                '</li>',
            item2: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-wrapper">' +
                '<div class="columns">' +
                '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
                '<div class="column-title">' +
                '<a href="${data.fileurl}" target="_blank">' +
                '<div title="${name}">${name}</div>' +
                '<span>${size2}</span>' +
                '</a>' +
                '</div>' +
                '<div class="column-actions">' +
                '<a href="${data.fileurl}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="zmdi zmdi-download"></i></a>' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></a>' +
                '</div>' +
                '</div>' +
                '<div class="input-title">' +
                '<div class="input-group mt-2">\n' +
                '    <input type="text" class="form-control" id="file-title-${data.id}" placeholder="Datei-Titel" value="${data.title}">' +
                '<div class="input-group-append">' +
                '    <button class="btn btn-success btn-save-title" data-updateurl="${data.url}" type="button"><i class="zmdi zmdi-save"></i></button>' +
                '  </div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</li>',
            onItemShow: function(item) {
                // add sorter button to the item html
                item.html.find('.fileuploader-action-remove i').attr('class', 'zmdi zmdi-close');
                item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Eintrag verschieben"><i class="zmdi zmdi-arrows"></i></button>');
            }
        },
        afterRender: function(listEl, parentEl, newInputEl, inputEl) {
            var plusInput = parentEl.find('.fileuploader-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));


            plusInput.on('click', function() {
                api.open();
            });

            api.getOptions().dragDrop.container = plusInput;
        },
        upload: {
            url: $form.attr('action'),
            data: {_token: $form.find('[name="_token"]').val()},
            type: 'POST',
            enctype: 'multipart/form-data',
            start: true,
            synchron: true,
            beforeSend: null,
            onSuccess: function(result, item) {
                var data = {};

                if (result && result.files)
                    data = result;
                else
                    data.hasWarnings = true;

                // if success
                if (data.files.isSuccess && data.files.files[0]) {
                    item.name = data.files.files[0].name;
                    item.file_id =  data.files.files[0].data.id;
                    item.data.id = data.files.files[0].data.id;
                    item.data.url = data.files.files[0].data.url;
                    item.html.find('.column-title > div:first-child').text(data.files.files[0].name).attr('title', data.files.files[0].name);
                    item.html.find('.btn-save-title').data('updateurl', data.files.files[0].data.url);
                }

                // if warnings
                if (data.hasWarnings) {
                    for (var warning in data.warnings) {
                        alert(data.warnings[warning]);
                    }

                    item.html.removeClass('upload-successful').addClass('upload-failed');
                    return this.onError ? this.onError(item) : null;
                }

                item.html.find('.fileuploader-action-remove');
                item.html.find("input").attr('id', item.html.find("input").attr('id') + item.data.id);

                item.html.find('.column-title span').html(item.size2);
                setTimeout(function() {
                    item.progressBar.fadeOut(400);
                    alertify.success("Die Datei wurde erfolgreich hochgeladen.")
                }, 400);
            },
            onError: function(item) {
                if (item.progressBar) {
                    item.html.find('.column-title span').html(item.size2);
                    item.progressBar.hide().find('.bar').width(0);
                }

                item.upload.status != 'cancelled' && item.html.find('.fileuploader-action-retry').length == 0 ? item.html.find('.column-actions').prepend(
                    '<button type="button" class="fileuploader-action fileuploader-action-retry" title="Retry"><i class="fileuploader-icon-retry"></i></button>'
                ) : null;
            },
            onProgress: function(data, item) {
                item.html.find('.column-title span').html(data.percentage == 99 ? 'Uploading...' : data.loadedInFormat + ' / ' + data.totalInFormat);
                item.progressBar.show().find('.bar').width(data.percentage + "%");
            },
            onComplete: null,
        },
        onRemove: function(item) {
            var token = this.listInput.parents("form").find("input[name='_token']").val();
            // $.post('./php/ajax_remove_file.php', {
            //     file: item.name
            // });
            $.ajax({
                type: "POST",
                url: item.data.url,
                data: {"_method": "delete","_token": token},
                dataType: "json",
                success: function(json){
                    alertify.success(json.message);
                },
                failure: function(errMsg) {
                }
            });
        },
        captions: $.extend(true, {}, $.fn.fileuploader.languages['de'], {
            feedback: 'Dateien hierherziehen',
            or: 'oder',
            button: 'Datei auswählen',
        }),
        sorter: {
            selectorExclude: null,
            placeholder: null,
            scrollContainer: window,
            onSort: function(list, listEl, parentEl, newInputEl, inputEl) {
                var token = inputEl.parents("form").find("input[name='_token']").val();
                var url = inputEl.parents("form").data('orderurl');

                var api = $.fileuploader.getInstance(inputEl),
                    fileList = api.getFiles(),
                    list = [];

                // prepare the sorted list
                api.getFiles().forEach(function(item) {
                    if (item.data)
                        list.push({
                            id: item.data.id,
                            index: item.index
                        });
                });

                $.ajax({
                    type: "POST",
                    url: url,
                    data: {"_token": token, "_list": JSON.stringify(list)},
                    dataType: "json",
                    success: function(json){
                        alertify.success(json.message);
                    },
                    failure: function(errMsg) {
                    }
                });
            }
        },
        addMore: true,
        dialogs: {
            confirm: function(text, callback, item) {
                swal.fire({
                    title: 'Datei löschen',
                    icon: 'warning',
                    html: 'Wollen Sie diese Datei &quot;<strong>'+item.name+'</strong>&quot; wirklich löschen?',
                    showCancelButton: true,
                    confirmButtonText: 'Löschen!',
                    cancelButtonText: 'Abbrechen'
                }).then((result) => {
                    if (result != null && result.value) {
                        callback();
                    }
                });
            }
        },
    });

   $(document).on("click", ".btn-save-title", function(e) {
       var $item = $(this);
       var token = $(this).parents("form").find("input[name='_token']").val();
       var url = $(this).data('updateurl');
       var title = $(this).parents(".input-group").find("input").val();
       $(this).find("i").attr('class', 'zmdi zmdi-refresh fa-spin').attr('disabled', true);;

       $.ajax({
           type: "PUT",
           url: url,
           data: {"_token": token, "title": title},
           dataType: "json",
           success: function(json){
               $item.find("i").attr('class', 'zmdi zmdi-save').attr('disabled', false);
               alertify.success(json.message);
           },
           failure: function(errMsg) {
           }
       });
   });

    $(document).on("click", ".webinardownload-adduser .add", function(e) {
        e.preventDefault();

        var url = $(this).attr('href');

        swal.fire({
            title: 'Wieviele Zugänge sollen für dieses Webinar angelegt werden?',
            icon: 'question',
            input: 'range',
            inputAttributes: {
                min: 1,
                max: 50,
                step: 1
            },
            inputValue: 1,
            confirmButtonText: '<i class="zmdi zmdi-plus"></i> Zugänge anlegen',
            customClass: {
                confirmButton: 'btn btn-success',
            },
            confirmButtonColor: '#068120',
        }).then((result) => {
            var token = $(this).parents(".webinardownload-adduser").find("form input[name='_token']").val();
            var table = $(this).parents(".webinardownload-adduser").find("table");

            if (typeof result.value ==  "undefined") return;

            $.ajax({
                type: "POST",
                url: url,
                data: {"_token": token, "items": result.value},
                dataType: "json",
                success: function(json){
                    table.DataTable().draw(true);
                    alertify.success(json.message);
                },
                failure: function(errMsg) {
                }
            });
        })
    });
});


