jQuery(document).ready(function() {
    console.debug(1);

    if ($('.login')[0]) {
        $('body').on('click', '.l-block [data-block]', function(e){
            e.preventDefault();
            var z = $(this).data('block');
            var t = $(this).closest('.l-block');
            var c = $(this).data('bg');

            t.removeClass('toggled');

            setTimeout(function(){
                $('.login').attr('data-lbg', c);
                $(z).addClass('toggled');
            });
        });

        // $("form.loginForm").ajaxForm({
        //     infoMessageContainer: "#l-login .message-block",
        //     onSuccess: function() {
        //
        //     },
        //     onErrors: function() {
        //     }
        // });
        //
        // $("form.forgotpasswordForm").ajaxForm({
        //     infoMessageContainer: "#l-forget-password .message-block",
        //     onSuccess: function() {
        //
        //     },
        //     onErrors: function() {
        //     }
        // });
    }

    $(".logout").click(function(e) {
        e.preventDefault();

       $("#logout-form").submit();
    });
});
