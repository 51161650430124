window.jQuery = require('jquery');
window.$ = window.jQuery;
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-responsive-bs4');
require('node-waves');
window.slidebars = require('./plugins/slidebars');
window.swal = require('sweetalert2');
require('jquery-validation');
window.alertify = require('alertifyjs');
require('../vendor/fileuploader-2.2/src/jquery.fileuploader');


alertify.set('notifier','position', 'top-right');

window.CodeMirror = require('codemirror');
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css');
require('codemirror/mode/htmlmixed/htmlmixed');
require('@emmetio/codemirror-plugin/dist/browser');

require('./app/login');
require('./app/navigation');
require('./app/datatables');
require('./app/cards');
require('./app/codemirror');
require('./app/webinardownloads');
require('./app/admin/member');
require('./app/admin/leadhistory');
require('./app/media-single');
require('./app/admin/facebookpost_mediaupload');
require('./app/copy');

// Import TinyMCE
var tinymce = require('tinymce/tinymce');

// Default icons are required for TinyMCE 5.3 or above
require('tinymce/icons/default');

// A theme is also required
require('tinymce/themes/silver');

// Any plugins you want to use has to be imported
require('tinymce/plugins/paste');
require('tinymce/plugins/link');
require('tinymce/plugins/emoticons');

