jQuery(document).ready(function($) {
    $(document).on("click", ".copy-text",function(e) {
        navigator.clipboard.writeText($(this).data('text'));

        var orgText = $(this).find("span").html();
        $(this).find("span").html($(this).data("donetext"));

        var element = $(this);

        setTimeout(function(){
            element.find("span").html(orgText);
        }, 3000);

    });
});
