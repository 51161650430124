jQuery(document).ready(function() {
    document.addEventListener("touchstart", function(){}, true);

    $('.mobile-site-container > .container').css('min-height', $(window).height() - $('.mobile-site-container').height() + 'px');

    var controller = new slidebars();
    controller.init();

    $( '.mobile-toggle-left, .mobile-slidebar-close' ).on( 'click', function ( event ) {
        event.stopPropagation();
        event.preventDefault();

        controller.toggle( 'sidebar-canvas' );

        if ($('.mobile-toggle-left').hasClass('collapsed')) {
            $('.mobile-toggle-left').removeClass('collapsed');
        } else {
            $('.mobile-toggle-left').addClass('collapsed');
        }
    } );

    // Close any
    $( document ).on( 'click', '.js-close-any', function ( event ) {
        if ( controller.getActiveSlidebar() ) {
            event.preventDefault();
            event.stopPropagation();
            controller.close();
        }
    } );

    // Add close class to canvas container when Slidebar is opened
    $( controller.events ).on( 'opening', function ( event ) {
        $( '[canvas]' ).addClass( 'js-close-any' );
    } );

    // Add close class to canvas container when Slidebar is opened
    $( controller.events ).on( 'closing', function ( event ) {
        $( '[canvas]' ).removeClass( 'js-close-any' );
        $('.mobile-toggle-left').removeClass('collapsed');
    } );
} );
